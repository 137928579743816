import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const SlideList = [
    {
        textPosition: 'text-center',
        title: 'Animated Tours',
        description: 'Experience the richness of minerals and the stories of the miners.',
    }
];

function AnimatedHeader(props) {

    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "rudnik/skrat-2.jpg" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const imageData = data.desktop.childImageSharp.fluid;

    const value = SlideList[0];

    return (
        <div className="slider-activation im_modern_slider" data-black-overlay="9" style={{minHeight: "33vh"}}>
            <BackgroundImage Tag="div" className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" fluid={imageData} backgroundColor={`#040e18`} data-black-overlay="7">
                <div className="container">
                    <div className="slide-style-2 d-flex align-items-center justify-content-center">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`inner ${value.textPosition}`}>
                                    <h1 className="page-heading">{value.title} <br /></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </div>
    );
}

export default AnimatedHeader ;