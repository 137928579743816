import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AnimatedGoodToKnow from "./AnimatedGoodToKnow";

function AnimatedExplorationsDescriptions(props) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--1 text-center text-lg-left">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-xl-6 order-1 mt-4 order-lg-0 ">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/skrat-1.jpg"
                                    alt="With a dwarf through the Sitarjevec mine"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">With a dwarf through the Sitarjevec mine</h2>
                                    <p className="description">
                                        A mischievous, prankster dwarf from Sitarjevec takes the visitors to explore the underground world of the Export pit. In a playful and humorous way, visitors will learn more about the mine - what they look like and how heavy the rocks here are, how minerals glow, learn about mining tools, interesting stories of miners and the inhabitants of the mine - bats. Animated guided tours are an experience for the whole family.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AnimatedGoodToKnow/>

            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 col-xl-6 order-1 mt-4 order-lg-0 ">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/skrat-4.jpg"
                                    alt="Tour of the Export Pit"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Fairytales and stories of the Sitarjevec Mine</h2>
                                    <p className="description">
                                        In the fairytale corner, we also like to tell a story to the youngest visitors when visiting the Export Pit. Our local writer Darinka Kobal wrote some of these in her book "In the Dark Depths of Sitarjevec".
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Litko and his story</h2>
                                    <p className="description">Litko is the miner and mascot of the Litija Carnival. This one has the longest tradition in Slovenia. Litko has lived in the Sitarjevec mine and hides in its depths. If you’re lucky, you might notice him in some side pit.
                                    </p>

                                    <p className="description">
                                        A long time ago, in a small town, between two hills, through which the Sava River flows, a carnival took place every year. It has been more than 170 years since the beginning of this beautiful tradition. People dressed up in masquerades, walking around the city and rejoicing. Among them was a sad, lonely miner who wanted to dress up in a masquerade, but his colleagues did not allow him to do so. For days and years, he wished he was a favorite masquerade at the carnival and that everyone would love him.
                                    </p>

                                    <div className="description">
                                        One day at work in a mine, he dreamed of a carnival mask and fell asleep. The next time he woke up, he was all happy to see that he was dressed in a very special costume. He peeked out of the mine and found that the town was preparing for the carnival again. He decided to come to town - happy and dressed in a costume!
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/litko-pred-rudnikom-sitarjevec.jpeg"
                                    alt="The mascot Litko in front of the Sitarjevec Mine"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AnimatedExplorationsDescriptions;