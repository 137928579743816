import React from 'react';

import SEO from "../../../components/en/seo/Seo";
import Header from "../../../components/en/headers/Header";

import AnimatedExplorationsDescriptions from "../../../components/en/animated-explorations/AnimatedExplorationsDescriptions";
import Footer from "../../../components/en/layouts/Footer";
import CallToAction from "../../../components/en/layouts/CallToAction";
import AnimatedHeader from "../../../components/en/animated-explorations/AnimatedHeader";

function AnimatedTours({data}) {
    return (
        <div>
            <SEO title="Animated Tours"
                 description=" In a playful and humorous way, visitors will learn more about the mine - what they look like and how heavy the rocks here are, how minerals glow, learn about mining tools, interesting stories of miners and the inhabitants of the mine - bats. Animated guided tours are an experience for the whole family."
            />
            <Header/>
            <AnimatedHeader/>
            <AnimatedExplorationsDescriptions/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export default AnimatedTours;